import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import {i18n} from './i18n';
import store from './store'
import Axios from 'axios'
import VueMeta from 'vue-meta'

// import VueNumber from 'vue-number-animation'

// Vue.use(VueNumber)


// set global axios like this
Vue.prototype.$axios = Axios;
import VueAxios from "vue-axios";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.use(VueAxios, Axios);
Vue.config.silent = true
Vue.config.productionTip = false
Axios.defaults.baseURL = 'https://alkafeel.net/alkafeel_back_test/api/v1/';




import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBPFDMtjV0nyVdedEBT169klxYqGCvy3tI',
    libraries: 'places',
  },
  

  installComponents: true,



})




import router from './router'


Vue.use(VueRouter)


//Config
Vue.config.productionTip = false
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});

Vue.filter('formatMon', function(value) {
  if (value) {
    return moment(String(value)).format('DD MMM  YYYY')
  }
});

Vue.filter('formatMonn', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM DD , YYYY')
  }
});

Vue.use(VueMeta)
  
Vue.mixin({
  data: function () {
    return {
      Url:    'https://alkafeel.net/alkafeel_back_test/api/v1/',
      resUrl: 'https://alkafeel.net/alkafeel_back_test/',
      http: 'https://'
    }
  }
})
Vue.config.productionTip = false;
Vue.config.productionTip = false;
Vue.config.silent = true
Vue.config.productionTip = false




// const Home = { template: '<div><h2>Home Page</h2></div>' }
// const About = { template: '<div><h2>About Page</h2></div>' }
// const Contact = { template: '<div><h2>Contact Page</h2></div>' }

// const routes = [
//   { path: '/', component: Home },
//   { path: '/about', component: About },
//   { path: '/contact', component: Contact }
// ]

// const router = new VueRouter({
//   routes,
//   mode: 'history'
// })
let authenticate = Promise.resolve({ role:store.state.role });

authenticate.then(user => {
  user
new Vue({
  el: '#app',
  router,
  
  store,
  i18n,
  Axios,
  vuetify,
  render: h => h(App),

  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  }
})
})