


// i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import router from './router'
Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || 'ar',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar',
})

const loadedLanguages = []

const langs_ini = ['ar', 'en', 'pr', 'tr', 'ur', 'fr', 'de', 'sw', 'es']

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }


  if (lang == null) lang='ar';
  return  axios.get(`lang/getWord/${lang}`)

  .then(response => {
    let msgs = response.data
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, msgs)
    setI18nLanguage(lang)
   // location.href = "?lang="+lang;
  })
}

// router.js
router.beforeEach((to, from, next) => {
  let params = (new URL(document.location)).searchParams;
  let set = 0;
  var lang =  localStorage.getItem("lang");
  lang = params.get("lang");
  localStorage.getItem("lang")
  langs_ini.forEach (element => {
  if (element === lang) {
    set = 1;
    loadLanguageAsync(lang).then(() => next())
  }
  })
  if (!set) loadLanguageAsync('ar').then(() => next())
})




   


  